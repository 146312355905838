.navbar-nav .nav-item .nav-link {
  border-bottom: 3px solid transparent;
  transition: transform .3s ease-out, border-bottom .3s ease-out;
  transform: translateX(0);
  transition-delay: .2s;
}

.navbar {
  height: 55px;
}

.navbar-nav .nav-item .nav-link.active,
.navbar-nav .nav-item .nav-link:hover {
  border-bottom: 4px solid #8ed1fc;
}

.dropdown-menu a:hover {
  background-color: #f8f9fa;
}
.haralin {
  font-size: 50px;
  line-height: 1;
  color: var(--text);
  font-family: Comic Sans ;
}
.price{
  font-weight: 700;
  color: #5bd87c;
}
.card {
  overflow: hidden;
  cursor: move;
}

.card img {
  transition: transform 0.3s;
}

.card img:hover {
  transform: scale(1.5);
}
.img-text{
 font-weight: 600;
}
.value-container {
  display: flex;
  align-items: center;
  border: 1px solid black;
  justify-content: center;
  width: 300px;
  height: 80px;
}

button {
  background-color: #FFFFFF;
  border: 1px solid #000000;
  color: #000000;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

button.selected {
  background-color: #000000;
  color: #FFFFFF;
}
.bread{
  min-height: 150px;
background-image: url('../all_product/breadcrumb.jpg') ;
background-repeat: no-repeat;
background-size: cover;
}
.bread-item{
  margin-top: 30px;
}
.breadcrumb-row-2 .breadcrumb-row-1{
  margin-top: 45px;
}
.bread-text{
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.contain{
  min-height: 650px;
  overflow-y: auto;
  overflow-x: auto;
}
.med{
  max-width: 80px;
  max-height: 80px;
}
.button-war{
  padding: 1.2rem;
  padding: 10px 10px;
}
.nav-text{
  color: #333;
  font-family: Poppins, sans-serif;
}
.top{
  margin-top: 15vh;
}
.decoration{
  text-decoration: none;
  color: white;
}