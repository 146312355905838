body {
    overflow: auto;
  }
  * {
    font-family: 'Lato', sans-serif;
  }
  
  body::-webkit-scrollbar {
    width: 8px;
  }
  
  body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  body::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;500&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&family=Playfair:ital@0;1&family=Roboto&family=Roboto+Slab:wght@100&display=swap');
  h1{
    font-family: 'Playfair', serif;
  }
  .display-4{
    font-family: 'Playfair', serif;
  }
  .card-title{
    font-family: 'Roboto Slab', serif;
  }
  
 /* Float Cart */
.float-cart {
  position: fixed;
  top: 0;
  right: -50vh;
  width: 50vh;
  height: 100%;
  background-color: #f7f7f7; /* Light gray background */
  box-sizing: border-box;
  transition: right 0.1s;
}

.float-cart--open {
  right: 0;
}

.float-cart__footer {
  display: inline-block;

}


.float-cart__close-btn {
  width: 50px;
  height: 50px;
  color: #fff; /* White text color */
  background-color: #1d1313; /* Dark red background */
  text-align: center;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: -50px;
  cursor: pointer;
}

.float-cart__close-btn:hover {
  background-color: #101b0d; /* Darker red on hover */
}

/* Bag Icon */
.float-cart .bag {
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  background-image: url('./Images/bag-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.float-cart .bag--float-cart-closed {
  position: absolute;
  background-color: #1c492e; /* Green background */
  background-size: 50%;
  left: -60px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.float-cart .bag__quantity {
margin-left: 28px;
color: white;
}
.bag__quan{
  color: white;
 margin-top: -10vh;
}

/* Header */
.float-cart__header {
  color: #000;
  box-sizing: border-box;
  text-align: center;
  padding: 45px 0;
  background-color: #1c492e; 
}

.float-cart__header .header-title {
  font-weight: bold;
  font-size: 1.2em;
  vertical-align: middle;
  color: #fff; /* White text color */
  overflow-y: scroll;
}

.float-cart__shelf-container {
  position: relative;
  min-height: 280px;
  padding-bottom: 200px;
  height: 470px;
  overflow: auto;
  overflow-x: hidden;
}

.float-cart__shelf-container::-webkit-scrollbar {
  width: 8px;
}

.float-cart__shelf-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.float-cart__shelf-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.float-cart__shelf-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.float-cart__shelf-container .shelf-empty {
  color: #ececec;
  text-align: center;
  line-height: 40px;
}
.shelf-empty{
  color: black !important;
}

.float-cart__shelf-container .shelf-item {
  position: relative;
 
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  transition: background-color 0.2s, opacity 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.float-cart__shelf-container .shelf-item::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 5%;
}

.float-cart__shelf-container .shelf-item--mouseover {
  background: #0c0b10;
  color: #fff; /* White text color */
}

.float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .title {
  color: #fff; /* White text color */
}

.float-cart__shelf-container .shelf-item .shelf-item__thumb {
  margin-bottom: 10px;
}

.float-cart__shelf-container .shelf-item .shelf-item__thumb img {
  height: auto;
}

.float-cart__shelf-container .shelf-item .shelf-item__details .title {
  font-weight: bold;
  font-size: 1.1em;
}

.float-cart__shelf-container .shelf-item .shelf-item__details .quantity-input {
  display: flex;
  align-items: center;
}

.float-cart__shelf-container .shelf-item .shelf-item__details .quantity-input .quantity-button {
  width: 30px;
  height: 30px;
  background-color: #ececec;
  border: none;
  cursor: pointer;
}

.float-cart__shelf-container .shelf-item .shelf-item__details .quantity-input .quantity-input-field {
  width: 50px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 0.9em;
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
}

.float-cart__footer{
  position:fixed
}

.svj {
  margin-left: 20vh;
  margin-top: -2vh;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  opacity: 1;
}
.svj:hover {
  cursor: pointer;
}
.deco{
  text-decoration: none;
}
.quantity-input {
  display: flex;
  align-items: center;
  }
  
  .quantity-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  }
  
  .quantity-input-field {
    width: 2rem;
    text-align: center;
    height: 2vh;
  }
 /* Target number input type and remove spin buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Optional: Remove default margins */
}
input[type="number"] {
  appearance: none; /* Firefox: Set the input type to "textfield" */
}

  
  
  .quantity-button{
    width: 3rem;
    max-height: 2vh;
  }
  
  .quantity-button:not(:disabled):hover {
  background-color: #f0f0f0;
  }
  
  .quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  }
  .price{
    font-size: small;
    color: black;
  }
  .btn-sm {
    width: 1rem;
    height: 1rem;
    font-size: 0.8rem;
    line-height: 1;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cross{
    font-size: 1rem; /* Adjust the font size if needed */
    font-weight: 700; /* Optionally set the font weight for better visibility */
    opacity: 1;
    cursor: pointer;
    margin-top: 2vh;
  }
  .sub{
    margin-top: 3vh;
  }