
.navbar-nav .nav-item .nav-link {
  border-bottom: 3px solid transparent;
  transition: transform .3s ease-out, border-bottom .3s ease-out;
  transform: translateX(0);
  transition-delay: .2s;
}

.navbar-nav .nav-item .nav-link.active,
.navbar-nav .nav-item .nav-link:hover {
  border-bottom: 4px solid #8ed1fc;
}

.dropdown-menu a:hover {
  background-color: #f8f9fa;
}

.list-group {
  margin: 0 auto;
  border-radius: 5px;
}

.list-group-item.active {
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item {
  font-size: 16px;
  color: #333;
  border-color: #ddd;
  border-radius: 0;
}

.list-group-item:hover {
  background-color: #f0f0f0;
  color: black;
}

.subcategories:hover {
  cursor: pointer;
}


.list-group-item span.badge {
  float: right;
  margin-top: 5px;
}

.list-group-item p {
  margin-top: 5px;
  font-size: 14px;
  color: #777;
}

.custom-select {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #555;
}

.custom-select:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.icon-container {
  background-color: white;
  border: 1px solid #ccc;
}

.icon-container:hover {
  background-color: green;
}

.footer {
  background-color: #f5f5f5;
  padding: 50px 0;
}

.footer-2 {
  background-color: #f5f5f5;
  padding: 30px 0;
}

.footer h4 {
  color: #333;
  margin-bottom: 30px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #666;
  text-decoration: none;
}

.footer .social-icons a {
  display: flex;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;
  color: #000;
}

.footer .social-icons a:hover {
  color: green;
}

.footer .fa {
  font-size: 24px;
  margin-right: 5px;
}

.social-icons a i {
  display: inline-block;
  background-color: green;
  color: white;
  border-radius: 50%;
  padding: 10px;
  margin: 10px;
  height: 40px;
  width: 40px;
}

.social-icons a i:hover {
  background-color: #020000;
}

.social-icons {
  display: flex;
  flex-wrap: wrap;
}

.font-1 {
  font-family: Open Sans;
  font-size: 18px;
}

.font-2 {
  font-family: Open Sans;
  font-size: 18px;
}

.font-3 {
  font-family: Open Sans;
  font-size: 20px;
}

.font-4 {
  font-family: Times New Roman, serif;
  font-size: 20px;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.text-muted {
  color: #6c757d !important;
}

.hover-zoom {
  overflow: hidden;
}

.hover-zoom img {
  transition: transform 0.3s ease;
}

.hover-zoom:hover img {
  transform: scale(0.1);
}

.logo {
  max-width: 3rem;
}

.bread {
  min-height: 150px;
  background-image: url('./breadcrumb.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.bread-item {
  margin-top: 30px;
}

.breadcrumb-row-2 .breadcrumb-row-1 {
  margin-top: 45px;
}

.bread-text {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.list-content {
  text-decoration: none;
}

ul.list-group {
  list-style-type: none;
}

.item-list {
  background-color: #ddd !important;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #b90707;
}

.card-text {
  font-size: 20px;
  color: #777;
}
.title{
  font-size:medium;
}