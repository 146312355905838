

.hover-zoom {
overflow: hidden;
}

.hover-zoom img {
transition: transform 0.3s ease;
}

.hover-zoom:hover img {
transform: scale(0.5);
}
.gradient-button {
    background:linear-gradient(to right, #4CAF50, #FFFF00);
    color: white;
    border: 1px solid greenyellow;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add smooth transition */
}

.gradient-button:hover {
    background-color: #2ECC71; /* Change color on hover */
}

